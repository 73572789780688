import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import EmailIllustrationSrc from "images/email-illustration.svg";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);
const  CardColumn = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 max-w-sm`}
`;

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left
mt-[80px]`;

const Subheading = tw(SubheadingBase)`text-center md:text-left text-primary-1000`;
//const Heading = tw(SectionHeading)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight `;
const Heading = tw(SectionHeading)`
  mt-4 font-black text-left text-lg sm:text-xl lg:text-2xl text-center md:text-left leading-tight
`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`
const CenterHeading = tw.p`text-center font-black  sm:text-3xl lg:text-4xl`
const Card = styled.div`
  ${tw`flex flex-col sm:flex-row items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8`}
  .imageContainer {
    ${tw`border text-center rounded-full p-5 flex-shrink-0`}
    img {
      ${tw`w-6 h-6`}
    }
  }

  .textContainer { 
    ${tw`sm:ml-4 mt-4 sm:mt-2`}
  }

  .title {
    ${tw`mt-4 tracking-wide font-bold text-2xl leading-none whitespace-nowrap`}
    font-size: 22px;
  }

  .description {
    ${tw`mt-1 sm:mt-4 font-medium text-secondary-100 leading-loose `}
    text-align: justify;
  }
`;
const TwoColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-20 md:py-24`}
`;

export default ({
  cards = null,
  subheading = "Student",
  heading = <>List of features provided by <span  style={{
    background: '-webkit-linear-gradient(left,#02c197, #006a9a)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    fontSize:"25px",
  }}>TPO.AI <> <span style={{background:"rgb(36 62 99 / var(--tw-text-opacity))", WebkitBackgroundClip: 'text',}}>to</span></>  Student</span><wbr/></>,
  /* description = "Features for Students on TPO.AI Platform", */
  submitButtonText = "Contact Me",

  formAction = "#",
  formMethod = "get",
  textOnLeft = true, 
}) => {
  // The textOnLeft boolean prop can be used to display either the text on the left or right side of the image.
  const defaultCards = [
    {
      imageSrc: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQilaiTExcR9YLy_ycDhnZpGyB2ccOoTHJ3-wUtFFzibtBi9-t_MipQQUXLjQyAvWeczrQ&usqp=CAU",
      title: "Internship Opportunities",
      description: "Explore a wide range of industry-specific internship opportunities provided by reputable training partners through TPO.AI's platform.",
      color :'red'
    },
    {
      imageSrc: "https://cdn.pixabay.com/photo/2016/12/06/01/26/colour-1885352_640.jpg",
      title: " Training Partner Network",
      description: "Connect with a curated network of training partners representing various industries, ensuring students have access to high-quality and relevant internship opportunities.",
      color :'blue'

    },
    {
      imageSrc: "https://cdn.pixabay.com/photo/2015/12/09/01/02/mandalas-1084082_1280.jpg",
      title: " Learning Internships",
      description: "Engage in learning paths designed in collaboration with training partners, tailored to equip students with the necessary skills and knowledge for successful internships.",
      color :'yellow'

    },
    {
      imageSrc: "https://hbr.org/resources/images/article_assets/2018/08/R1805D_CHIN.jpg",
      title: " Internship Updates",
      description: "Receive timely updates on internship openings and opportunities, keeping students informed about the latest industry trends and demands for internships.",
      color :'pink'

    },
    {
      imageSrc: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRU54eyJ_fzl9Gy4ZKzZ9fnObJjMsp6yCfgSA&usqp=CAU",
      title: "Learning Resources",
      description: "Access a wealth of learning resources provided by training partners, including internship guides, project materials, and industry insights, enhancing the overall learning experience during internships.",
      color :'brown'

    },
    {
      imageSrc: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcREtLoJmv6c_zyeH25zH2i1EuBugrCZE62dMQ&usqp=CAU",
      title: "Communication Hub",
      description: "Utilize a centralized communication platform to interact with colleges, training partners, and potential employers, fostering clear communication throughout the internship application and placement process.",
      color :'black'

    },
    {
      imageSrc: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRU54eyJ_fzl9Gy4ZKzZ9fnObJjMsp6yCfgSA&usqp=CAU",
      title: "Job Guidance",
      description: "Benefit from TPO.AI's placement and job guidance services, gaining insights into industry trends, job market demands, and career development opportunities to make informed decisions about their future.",
      color :'brown'

    },
    {
      imageSrc: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcREtLoJmv6c_zyeH25zH2i1EuBugrCZE62dMQ&usqp=CAU",
      title: "Performance Analytics",
      description: "Utilize analytics tools to track and assess personal performance during internships, gaining valuable feedback and insights for continuous improvement.",
      color :'black'

    },
    {
      imageSrc: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRU54eyJ_fzl9Gy4ZKzZ9fnObJjMsp6yCfgSA&usqp=CAU",
      title: "Alumni Mentorship",
      description: "Engage in mentorship programs facilitated by training partners, connecting with industry professionals and alumni who can provide guidance, support, and valuable insights into career paths.",
      color :'brown'

    },
    {
      imageSrc: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcREtLoJmv6c_zyeH25zH2i1EuBugrCZE62dMQ&usqp=CAU",
      title: "Student Information",
      description: "Enjoy a streamlined experience with seamless integration with the college's Student Information System, ensuring accurate and up-to-date information for internship applications and placements.",
      color :'black'

    },
    {
      imageSrc: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRU54eyJ_fzl9Gy4ZKzZ9fnObJjMsp6yCfgSA&usqp=CAU",
      title: "Event Networking",
      description: "Participate in events organized in collaboration with training partners, such as internship fairs and industry forums, providing opportunities for networking and building connections in the professional world",
      color :'brown'

    },
    {
      imageSrc: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcREtLoJmv6c_zyeH25zH2i1EuBugrCZE62dMQ&usqp=CAU",
      title: "AI-Based Resume",
      description: "Leverage an AI-powered resume builder to create professional and tailored resumes, highlighting key skills, experiences, and achievements to stand out to potential employers",
      color :'black'

    }
   
  ];

  if (!cards) cards = defaultCards;

  return (
    <Container id="student">
      <TwoColumn>
        {/* <ImageColumn>
          <Image imageSrc={EmailIllustrationSrc} />
        </ImageColumn> */}
          <iframe width="660" height="315" src="https://www.youtube.com/embed/aycr1LpJ4iw?si=gpND24XqI3wSFyY4" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>

        <TextColumn >
          <TextContent>
            {subheading && <Subheading style={{
              marginTop:"-60px",
              background: '-webkit-linear-gradient(left,#02c197, #006a9a)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              fontSize:"43px"
            }}>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            {/* <Description>{description}</Description> */}
          </TextContent>
        </TextColumn>
      </TwoColumn>
      <CenterHeading>Features</CenterHeading>
      <TwoColumnContainer>
      {cards.map((card, i) => (
            <CardColumn key={i}>
              <Card>
              <span className="imageContainer">
                <img src={card.imageSrc} alt="" width="350px" />
              </span>
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <p className="description">
                  {card.description}
                </p>
              </span>
            </Card>
            </CardColumn>
          ))}
          </TwoColumnContainer>
    </Container>
  );
};