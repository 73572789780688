import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import EmailIllustrationSrc from "images/email-illustration.svg";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);
const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left
mt-[80px] mr-[50px]`;

const Subheading = tw(SubheadingBase)`text-center md:text-left text-primary-1000`;
//const Heading = tw(SectionHeading)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight `;
const Heading = tw(SectionHeading)`
  mt-4 font-black text-left text-lg sm:text-xl lg:text-2xl text-center md:text-left leading-tight
`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`
const CenterHeading = tw.p`text-center font-black  sm:text-3xl lg:text-4xl`
const Card = styled.div`
  ${tw`flex flex-col sm:flex-row items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8`}
  .imageContainer {
    ${tw`border text-center rounded-full p-5 flex-shrink-0`}
    img {
      ${tw`w-6 h-6`}
    }
  }

  .textContainer { 
    ${tw`sm:ml-4 mt-4 sm:mt-2`}
  }

  .title {
    ${tw`mt-4 tracking-wide font-bold text-2xl leading-none whitespace-nowrap`}
    font-size: 22px;
  }

  .description {
    ${tw`mt-1 sm:mt-4 font-medium text-secondary-100 leading-loose `}
    text-align: justify;
  }
`;

const TwoColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-20 md:py-24`}
`;
const ColumnGrid = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 max-w-sm`}
`;

export default ({
  cards = null,
  subheading = "College Admin",
  heading = <>List of features provided by <span  style={{
    background: '-webkit-linear-gradient(left,#02c197, #006a9a)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    fontSize:"25px",
  }}>TPO.AI <> <span style={{background:"rgb(36 62 99 / var(--tw-text-opacity))", WebkitBackgroundClip: 'text',}}>to</span></>  College Admin</span><wbr/></>,
  //description = " Colleges benefit from TPO.AI's application as a platform connecting them with industry-based training partners for internships and serving as a placement and job guide for students",
  submitButtonText = "Contact Me",

  formAction = "#",
  formMethod = "get",
  textOnLeft = true, 
}) => {
  // The textOnLeft boolean prop can be used to display either the text on the left or right side of the image.
  const defaultCards = [
    {
      imageSrc: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQilaiTExcR9YLy_ycDhnZpGyB2ccOoTHJ3-wUtFFzibtBi9-t_MipQQUXLjQyAvWeczrQ&usqp=CAU",
      title: "Profile Builder",
      description: "A comprehensive interface for colleges to create detailed profiles showcasing their academic offerings, campus facilities, and placement records.",
      color :'red'
    },
    {
      imageSrc: "https://cdn.pixabay.com/photo/2016/12/06/01/26/colour-1885352_640.jpg",
      title: "Program Showcase",
      description: "Easily highlight and describe various academic programs, courses, and specializations offered by the college.",
      color :'blue'

    },
    {
      imageSrc: "https://cdn.pixabay.com/photo/2015/12/09/01/02/mandalas-1084082_1280.jpg",
      title: "Internship Opportunities",
      description: "Access a curated list of industry-specific training partners offering high-quality internship opportunities, aligning with the college's curriculum and enhancing students' practical skills.",
      color :'yellow'

    },
    {
      imageSrc: "https://cdn.pixabay.com/photo/2015/12/09/01/02/mandalas-1084082_1280.jpg",
      title: "Training Partner Network",
      description: "Connect with a diverse network of training partners representing various industries, ensuring colleges can offer internships that cater to a wide range of student interests and career paths.",
      color :'pink'

    },
    {
      imageSrc: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRU54eyJ_fzl9Gy4ZKzZ9fnObJjMsp6yCfgSA&usqp=CAU",
      title: "Learning Path Internships",
      description: "Collaborate with training partners to design customized learning paths that prepare students for successful internships, combining theoretical knowledge with practical, hands-on experience.",
      color :'brown'

    },
    {
      imageSrc: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcREtLoJmv6c_zyeH25zH2i1EuBugrCZE62dMQ&usqp=CAU",
      title: "Learning Resources",
      description: "Seamless integration of training partner resources, including internship guides, project materials, and industry insights, enriching the internship experience and promoting continuous learning.",
      color :'black'

    },
    {
      imageSrc: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRU54eyJ_fzl9Gy4ZKzZ9fnObJjMsp6yCfgSA&usqp=CAU",
      title: "Communication Hub",
      description: "Utilize a centralized communication platform to facilitate efficient collaboration between colleges, students, and training partners, ensuring clear communication throughout the internship process.",
      color :'brown'

    },
    {
      imageSrc: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcREtLoJmv6c_zyeH25zH2i1EuBugrCZE62dMQ&usqp=CAU",
      title: "Placement Guidance",
      description: "Access TPO.AI's placement and job guidance services to help students navigate their career paths, providing insights into industry trends, job market demands, and career development opportunities.",
      color :'black'

    },
    {
      imageSrc: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRU54eyJ_fzl9Gy4ZKzZ9fnObJjMsp6yCfgSA&usqp=CAU",
      title: "Analytics for Internship",
      description: "Leverage analytics tools to track the success of internships, monitor student performance, and gather valuable insights for continuous improvement in internship programs.",
      color :'brown'

    },
    {
      imageSrc: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcREtLoJmv6c_zyeH25zH2i1EuBugrCZE62dMQ&usqp=CAU",
      title: "Marketing Initiatives",
      description: "Collaborate on joint marketing efforts to promote internship opportunities, industry collaborations, and successful student stories, enhancing the college's reputation and attracting more opportunities",
      color :'black'

    },
    {
      imageSrc: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRU54eyJ_fzl9Gy4ZKzZ9fnObJjMsp6yCfgSA&usqp=CAU",
      title: "Event Coordination",
      description: "Collaborate with training partners on the organization and promotion of events such as internship fairs and industry forums, fostering networking opportunities between students and potential employers",
      color :'brown'

    },
    {
      imageSrc: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcREtLoJmv6c_zyeH25zH2i1EuBugrCZE62dMQ&usqp=CAU",
      title: "Secure Data",
      description: "Implement secure data sharing protocols to protect the privacy and confidentiality of student and college information, ensuring trust and compliance with data security standards.",
      color :'black'

    }
   
  ];

  if (!cards) cards = defaultCards;

  return (
    <Container id="collegeadmin">
      <TwoColumn>
       {/*  <ImageColumn>
          <Image imageSrc={EmailIllustrationSrc} />
        </ImageColumn>
 */}
    <iframe width="660" height="315"  src="https://www.youtube.com/embed/UW0HZd7Qu4I?si=umjQNnYI57OxGBEg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
        <TextColumn >
          <TextContent>
            {subheading && <Subheading style={{
              marginTop:"-60px",
              background: '-webkit-linear-gradient(left,#02c197, #006a9a)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              fontSize:"43px"
            }}>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            {/* <Description>{description}</Description> */}
          </TextContent>
        </TextColumn>
      </TwoColumn>
      <CenterHeading>Features</CenterHeading>
      <TwoColumnContainer>
      {cards.map((card, i) => (
            <ColumnGrid key={i}>
            <Card>
              <span className="imageContainer">
                <img src={card.imageSrc} alt="" width="350px" />
              </span>
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <p className="description">
                  {card.description}
                </p>
              </span>
            </Card>
          </ColumnGrid> 
          ))}
          </TwoColumnContainer>
    </Container>
  );
};
