import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import EmailIllustrationSrc from "images/email-illustration.svg";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left
mt-[80px]`;

const Subheading = tw(SubheadingBase)`text-center md:text-left text-primary-1000`;
//const Heading = tw(SectionHeading)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight `;
const Heading = tw(SectionHeading)`
  mt-4 font-black text-left text-lg sm:text-xl lg:text-2xl text-center md:text-left leading-tight
`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`
const CenterHeading = tw.p`text-center font-black  sm:text-3xl lg:text-4xl`

const Card = styled.div`
  ${tw`flex flex-col sm:flex-row items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8`}
  .imageContainer {
    ${tw`border text-center rounded-full p-5 flex-shrink-0`}
    img {
      ${tw`w-6 h-6`}
    }
  }

  .textContainer { 
    ${tw`sm:ml-4 mt-4 sm:mt-2`}
  }

  .title {
    ${tw`mt-4 tracking-wide font-bold text-2xl leading-none whitespace-nowrap`}
  }

  .description {
    ${tw`mt-1 sm:mt-4 font-medium text-secondary-100 leading-loose `}
    text-align: justify;
  }
`;
const TwoColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-20 md:py-24 `}
`;
const ColumnGrid = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 max-w-sm`}
`;

export default ({
  cards = null,
  subheading = "Tutor",
  heading = <>List of features provided by <span  style={{
    background: '-webkit-linear-gradient(left,#02c197, #006a9a)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    fontSize:"25px",
  }}>TPO.AI <> <span style={{background:"rgb(36 62 99 / var(--tw-text-opacity))", WebkitBackgroundClip: 'text',}}>to</span></> Tutor</span><wbr/></>,
 /*  description = "Features for Tutors on TPO.AI Platform", */
  submitButtonText = "Contact Me",

  formAction = "#",
  formMethod = "get",
  textOnLeft = true, 
}) => {
  // The textOnLeft boolean prop can be used to display either the text on the left or right side of the image.
  const defaultCards = [
    {
      imageSrc: "https://media.giphy.com/media/ZVik7pBtu9dNS/giphy.gif ",
      title: " Profile Creation",
      description: "Craft a comprehensive profile on the TPO.AI platform, showcasing qualifications, expertise, and teaching experience. Emphasize skills and specializations to attract tailored training opportunities.",
      color :'red'
    },
    {
      imageSrc: "https://cdn.pixabay.com/photo/2016/12/06/01/26/colour-1885352_640.jpg",
      title: "Match Companies",
      description: "Explore and select training companies seeking freelance trainers with specific skills on the TPO.AI platform.Receive personalized recommendations based on expertise and preferences.",
      color :'blue'

    },
    {
      imageSrc: "https://cdn.pixabay.com/photo/2015/12/09/01/02/mandalas-1084082_1280.jpg",
      title: "Flexible Availability ",
      description: "Set and update availability for training sessions directly on the TPO.AI platform, ensuring a flexible schedule.Seamlessly integrate with personal calendars for efficient time management.",
      color :'yellow'

    },
    {
      imageSrc: "https://hbr.org/resources/images/article_assets/2018/08/R1805D_CHIN.jpg",
      title: " Course and Content ",
      description: "Upload teaching materials, resources, and curriculum outlines directly on the TPO.AI platform to showcase your expertise. Provide samples of previous work or training sessions for potential clients.",
      color :'pink'

    },
    {
      imageSrc: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRU54eyJ_fzl9Gy4ZKzZ9fnObJjMsp6yCfgSA&usqp=CAU",
      title: " Communication Tools",
      description: "Utilize advanced messaging features on the TPO.AI platform to communicate with training companies, clarify requirements, and negotiate terms.Foster collaboration through dedicated discussion forums and announcement boards.",
      color :'brown'

    },
    {
      imageSrc: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcREtLoJmv6c_zyeH25zH2i1EuBugrCZE62dMQ&usqp=CAU",
      title: "Ratings and Reviews ",
      description: "Receive and display ratings and reviews from previous engagements directly on the TPO.AI platform. Build and showcase a positive reputation to attract more opportunities.",
      color :'black'

    },
    {
      imageSrc: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRU54eyJ_fzl9Gy4ZKzZ9fnObJjMsp6yCfgSA&usqp=CAU",
      title: " Performance Analytics",
      description: "Access robust analytics tools on the TPO.AI platform to track the effectiveness of training sessions. Monitor student engagement, performance, and feedback for continuous improvement.",
      color :'brown'

    },
    {
      imageSrc: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcREtLoJmv6c_zyeH25zH2i1EuBugrCZE62dMQ&usqp=CAU",
      title: "Payment Gateway ",
      description: "Seamlessly integrate with secure payment gateways on the TPO.AI platform for transparent and timely payment processing.Track earnings and manage financial transactions conveniently within the platform.",
      color :'black'

    },
    {
      imageSrc: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRU54eyJ_fzl9Gy4ZKzZ9fnObJjMsp6yCfgSA&usqp=CAU",
      title: "Support and Exposure:",
      description: "Benefit from marketing initiatives conducted by the TPO.AI platform to increase visibility. Showcase your profile and expertise to a broader audience, expanding your potential client base.",
      color :'brown'

    },
    {
      imageSrc: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcREtLoJmv6c_zyeH25zH2i1EuBugrCZE62dMQ&usqp=CAU",
      title: "Training Resources",
      description: "Utilize resources offered by the TPO.AI platform to enhance teaching skills and stay informed about the latest trends in education and training. Access a wealth of training materials for continuous professional development.",
      color :'black'

    },
    {
      imageSrc: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRU54eyJ_fzl9Gy4ZKzZ9fnObJjMsp6yCfgSA&usqp=CAU",
      title: "Career Growth  ",
      description: "Tools to promote courses within the TPO.AI ecosystem and attract a larger audience.Marketing resources and strategies to enhance course visibility.",
      color :'brown'

    },
  ];

  if (!cards) cards = defaultCards;

  return (
    <Container id="tutor">
      <TwoColumn>
       {/*  <ImageColumn>
          <Image imageSrc={EmailIllustrationSrc} />
        </ImageColumn>
 */}
     <iframe width="660" height="315" src="https://www.youtube.com/embed/tV0JhKUECck?si=kTGhvqDb14qQYDTi" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
        <TextColumn >
          <TextContent>
            {subheading && <Subheading style={{
              marginTop:"-60px",
              background: '-webkit-linear-gradient(left,#02c197, #006a9a)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              fontSize:"43px"
            }}>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            {/* <Description>{description}</Description> */}
          </TextContent>
        </TextColumn>
      </TwoColumn>
      <CenterHeading>Features</CenterHeading>
      <TwoColumnContainer>
      {cards.map((card, i) => (
            <ColumnGrid key={i}>
            <Card>
              <span className="imageContainer">
                <img src={card.imageSrc} alt="" width="350px" />
              </span>
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <p className="description">
                  {card.description}
                </p>
              </span>
            </Card>
          </ColumnGrid>
          ))}
          </TwoColumnContainer>
    </Container>
  );
};