import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import EmailIllustrationSrc from "images/email-illustration.svg";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left text-primary-1000`;
const Heading = tw(SectionHeading)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight `;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`
const Email = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col lg:flex-row`
const Input = tw.input`border-2 px-5 py-3 rounded focus:outline-none font-medium transition duration-300 hocus:border-primary-500`

const SubmitButton = tw(PrimaryButtonBase)`inline-block lg:ml-6 mt-6 lg:mt-0 bg-gradient-to-r from-primary-1000 to-secondary-1000`

export default ({
  subheading = "Contact Us",
  heading = <>Feel free to <span style={{
    background: '-webkit-linear-gradient(left,#02c197, #006a9a)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
  }}>get in touch</span><wbr /> with us.</>,
  description = " We would love to hear from you! Whether you have a question, feedback, or a business inquiry, feel free to reach out to us using the contact details below. Our dedicated team is ready to assist you.",
  submitButtonText = "Contact Me",

  formAction = "#",
  formMethod = "get",
  textOnLeft = true,
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  return (
    <Container id="contactsales">
      <TwoColumn>
        <ImageColumn>
          <Image imageSrc={EmailIllustrationSrc} />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            {subheading && <Subheading style={{
              marginTop: "-60px",
              background: '-webkit-linear-gradient(left,#02c197, #006a9a)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
            }}>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            <Description>{description}</Description>
            <Email
              style={{
                background: '-webkit-linear-gradient(left,#02c197, #006a9a)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent', fontSize: "25px", fontWeight: 'bold'
              }}
            > <span style={{ background: "rgb(36 62 99 / var(--tw-text-opacity))", WebkitBackgroundClip: 'text', fontWeight: 'bold' }}>Email ID :</span> info@tpo.ai </Email>
            <Email
              style={{
                background: '-webkit-linear-gradient(left,#02c197, #006a9a)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent', fontSize: "25px", fontWeight: 'bold'
              }}
            > <span style={{ background: "rgb(36 62 99 / var(--tw-text-opacity))", WebkitBackgroundClip: 'text', fontWeight: 'bold' }}>Contact Us : </span>+91-8977595691  </Email>
            <Form action={formAction} method={formMethod}>
              <Input type="email" name="email" placeholder="Your Email Address" />
              <SubmitButton type="submit">{submitButtonText}</SubmitButton>
            </Form>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
